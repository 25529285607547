import { easeInOutQuint } from 'js-easing-functions';
import {Point, Color} from "paper/dist/paper-core"

/**
 * Utlity file for Trace It
 */

/**
 * Animates a paper item's stroke value in
 * 
 * @param {Item} item
 * @param {number} intitalValue
 * @param {number} amountOfChange 
 * @param {number} duration
 */
export const animateLineIn = (item, intitalValue, amountOfChange, duration) => {
    const startTime = Date.now()
    step()
    function step () {
        const elapsed = Date.now() - startTime
        item.style.strokeWidth = easeInOutQuint(elapsed, intitalValue, amountOfChange, duration)
        if (elapsed < duration)
            requestAnimationFrame(step)
    }
}

/**
 * Animates a paper item's size (works for circles)
 * 
 * @param {Item} item 
 * @param {number} intitalValue 
 * @param {number} amountOfChange 
 * @param {number} duration 
 */
export const animateCircleIn = (item, intitalValue, amountOfChange, duration) => {

    var center = item.bounds.center     
    const startTime = Date.now()

    step()
    function step () {
        const elapsed = Date.now() - startTime

        item.bounds.height = easeInOutQuint(elapsed, intitalValue, amountOfChange, duration)
        item.bounds.width = easeInOutQuint(elapsed, intitalValue, amountOfChange, duration)

        item.bounds.center = center

        if (elapsed < duration)
            requestAnimationFrame(step)
    }

}

/**
 * Animates the current paper js zoom level
 * 
 * @param {Paper} paper 
 * @param {number} intitalValue 
 * @param {number} amountOfChange 
 * @param {number} duration 
 */
export const animateCameraZoom = (paper, intitalValue, amountOfChange, duration, callback) => {
    const startTime = Date.now()
    step()
    function step () {
        const elapsed = Date.now() - startTime
        paper.view.zoom = easeInOutQuint(elapsed, intitalValue, amountOfChange, duration)
        if (elapsed < duration)
            requestAnimationFrame(step)
        else if (callback) callback()    }
}

/**
 * Moves paper view to supplied point
 * 
 * @param {*} paper 
 * @param {*} intitalValuePoint 
 * @param {*} amountOfChangePoint 
 * @param {*} duration 
 */
export const animateMoveToPoint = (paper, intitalValuePoint, amountOfChangePoint, duration, callback) => {
    const startTime = Date.now()
    step()
    function step () {
        const elapsed = Date.now() - startTime
        paper.view.center = new Point(
            easeInOutQuint(elapsed, intitalValuePoint.x, amountOfChangePoint.x, duration),
            easeInOutQuint(elapsed, intitalValuePoint.y, amountOfChangePoint.y, duration))

        if (elapsed < duration)
            requestAnimationFrame(step)
        else if (callback) callback()
    }
}

/**
 * Animates line being completed
 * 
 * @param {Object} topPath 
 * @param {Function} callback 
 */
export const animatelineComplete = (topPath, onCompleteCallback) => {
        
    var col = topPath.strokeColor
    var temp = new Color(col.r, col.g, col.b)
    temp.lightness = 0.9

    var start = topPath.strokeWidth
    var end = topPath.strokeWidth * 1.25

    var tween1 = topPath.tweenTo({ strokeColor: temp, strokeWidth: end },{
        easing: 'easeInOutCubic',
        duration: 100
    })
    tween1.then(() => {
        if (onCompleteCallback) onCompleteCallback()         
        topPath.tweenTo({ strokeColor: col, strokeWidth: start }, {
        easing: 'easeInOutCubic',
        duration: 150
    })})
}