import React from "react"
import paper, {Color, Point, Size} from "paper"
import {
    animateLineIn,
    animateCircleIn
} from "./util/tracingAnimations"
import {
    setupMouseWheelListener,
    setupPinchZoomListener,
    setupTracingTool,
    focusOnLine
} from "./util/tracingFunctions"
import {HIT_ZONE} from "./constants"
import {data} from "./util/data"
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import FullscreenIcon from '@mui/icons-material/Fullscreen';

class InstuctionsModal extends React.Component {

    render() {
        return (
        <Dialog
            open={this.props.open}
            onClose={() => this.props.onClose()}
        >
            <DialogTitle>
                Welcome to Trace It!
            </DialogTitle>
            <DialogContent>
            <DialogContentText>
                Instuctions<br/><br/>

                1. Select a Tracing exercise from the list.<br/>
                2. Use your mouse or finger to trace.<br/>
                3. Zoom in and out using the mouse wheel or pinch zoom.<br/>
                4. By dragging off the line you can pan around the line.<br/><br/>

                Have fun!
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={() => this.props.onClose()}>Okay, got it!</Button>
            </DialogActions>
        </Dialog>
        )
    }
}

function toggleFullscreen() {

    if (
      !document.fullscreenElement &&
      //@ts-ignore
      !document.mozFullScreenElement &&
      //@ts-ignore
      !document.webkitFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen)
        //@ts-ignore  
        document.documentElement.requestFullscreen()
      //@ts-ignore
      else if (document.documentElementmozRequestFullScreen)
        //@ts-ignore  
        document.documentElement.mozRequestFullScreen()
      //@ts-ignore
      else if (document.documentElement.webkitRequestFullscreen)
        //@ts-ignore
        document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
    } else {
      //@ts-ignore
      if (document.cancelFullScreen) document.cancelFullScreen()
      //@ts-ignore
      else if (document.mozCancelFullScreen) document.mozCancelFullScreen()
      //@ts-ignore
      else if (document.webkitCancelFullScreen) document.webkitCancelFullScreen()
    }
  }

class Tracing extends React.Component {
    
    
    state = {
        backgroundColor: "#f5f5f5",
        backgroundImage: null,
        miggiPointsModalActive  : false,
        intializing: false,
        selectExerciseActive: true,
        instructionsModalActive: true,
        selectedExercise: 9
    }

    setup = () => {

        /* Setup paper */
        var canvas = document.getElementById("canvas")
        paper.setup(canvas)

        /* Add traceIt references to paper */
        paper.traceIt = {}
        paper.traceIt.hitGroup = null
        paper.traceIt.pathsGroup = null
        paper.traceIt.currentHit = null
        paper.traceIt.topPaths = []
        paper.traceIt.bottomPaths = []
        paper.traceIt.tracedLineIndex = 0 
        paper.traceIt.zoomedLineWidth = 40

        /* Set zoom level */
        paper.view.zoom = 2

        /* Create groups */
        this.pathsGroup = new paper.Group()
        this.hitGroup = new paper.Group()

        /* Setup pinch and wheel zooming listeners */
        setupMouseWheelListener(canvas, paper);
        setupPinchZoomListener(canvas, paper);
        
        /* Setup and create tracing tool */
        var tracingTool = new paper.Tool()
        setupTracingTool(paper, tracingTool, () => console.log("Next"), () => setTimeout(() => {
            this.setState({miggiPointsModalActive: true})
        }, 800))
       
        /* Setup background */
        this.setState({
            backgroundColor: data[this.state.selectedExercise].options.backgroundColor,
            backgroundSVG: data[this.state.selectedExercise].options.backgroundSVG
        })

        /* Add groups */
        paper.traceIt.pathsGroup = new paper.Group()
        paper.traceIt.hitGroup = new paper.Group()

        var i

        /* Add tracing paths */
        for (i = 0; i < data[this.state.selectedExercise].bottomPaths.length; i++) {

            var topPath = data[this.state.selectedExercise].topPaths[i]
            let bottomPath = data[this.state.selectedExercise].bottomPaths[i]

            let newBottomPath = new paper.Path({
                style: {
                    strokeWidth: bottomPath.style.strokeWidth,
                    strokeColor: new Color(
                        bottomPath.style.strokeColor[1],
                        bottomPath.style.strokeColor[2],
                        bottomPath.style.strokeColor[3],
                        bottomPath.style.strokeColor[4]
                    ),
                    shadowColor: new Color(
                        bottomPath.style.shadowColor[1],
                        bottomPath.style.shadowColor[2],
                        bottomPath.style.shadowColor[3],
                        bottomPath.style.shadowColor[4]
                    ),                    
                    shadowBlur: bottomPath.style.shadowBlur,
                    strokeCap: bottomPath.style.strokeCap,
                    strokeJoin: bottomPath.style.strokeJoin,
                    shadowOffset: bottomPath.style.shadowOffset
                }
            })

            bottomPath.segments.forEach((segment) => {
                newBottomPath.add(new paper.Segment(
                    new Point(segment.pointX, segment.pointY), 
                    new Point(segment.handleInX, segment.handleInY), 
                    new Point(segment.handleOutX, segment.handleOutY)
                ))
            })

            let newTopPath = new paper.Path({
                 style: {
                    strokeWidth: topPath.style.strokeWidth,
                    strokeColor: new Color(
                        topPath.style.strokeColor[1],
                        topPath.style.strokeColor[2],
                        topPath.style.strokeColor[3],
                        topPath.style.strokeColor[4]
                    ),
                    shadowColor: new Color(
                        topPath.style.shadowColor[1],
                        topPath.style.shadowColor[2],
                        topPath.style.shadowColor[3],
                        topPath.style.shadowColor[4]
                    ),                    
                    shadowBlur: topPath.style.shadowBlur,
                    strokeCap: topPath.style.strokeCap,
                    strokeJoin: topPath.style.strokeJoin,
                    shadowOffset: topPath.style.shadowOffset
                }
            })
            topPath.segments.forEach((segment) => {
                newTopPath.add(new paper.Segment(
                    new Point(segment.pointX, segment.pointY), 
                    new Point(segment.handleInX, segment.handleInY), 
                    new Point(segment.handleOutX, segment.handleOutY)
                ))
            })

            newBottomPath.ref = newTopPath
            newTopPath.ref = newBottomPath

            paper.traceIt.topPaths.push(newTopPath)
            paper.traceIt.bottomPaths.push(newBottomPath)

            paper.traceIt.pathsGroup.addChild(newBottomPath)
            paper.traceIt.pathsGroup.addChild(newTopPath)

        }

        /* Setup dash array of top group objects */
        for (i = 0; i <  paper.traceIt.topPaths.length; i++) {
            
            var path =  paper.traceIt.topPaths[i]
            
            path.dashArray = [path.length, path.length]
            path.dashOffset = path.length;
            
            // Add hit zone
            var hit = new paper.Path.Circle(null,path.strokeWidth/2)
            hit.type = HIT_ZONE
            hit.topPath = path
            hit.position = path.getPointAt(0)
            hit.fillColor = path.strokeColor

            // Add hit zone to hit group
            paper.traceIt.hitGroup.addChild(hit)
        }
    
        /* Make all paths invisible except for the first */
        for (i = 1; i < paper.traceIt.topPaths.length; i++) {
            paper.traceIt.topPaths[i].visible = false
            paper.traceIt.bottomPaths[i].visible = false
            paper.traceIt.hitGroup.children[i].visible = false    
        }
            
        /* Animate lines in */
        animateCircleIn(
            paper.traceIt.hitGroup.children[paper.traceIt.tracedLineIndex], 
            0.1,
            paper.traceIt.topPaths[paper.traceIt.tracedLineIndex].style.strokeWidth - 0.1,
            750
        )

        animateLineIn(
            paper.traceIt.bottomPaths[paper.traceIt.tracedLineIndex],
            0, 
            paper.traceIt.bottomPaths[paper.traceIt.tracedLineIndex].strokeWidth,
            750
        )
        
        /* Center and correct zoom */
        paper.view.center = paper.traceIt.bottomPaths[paper.traceIt.tracedLineIndex].segments[0].point
        paper.view.zoom = paper.traceIt.zoomedLineWidth / paper.traceIt.topPaths[paper.traceIt.tracedLineIndex].style.strokeWidth

        this.setState({intializing: true})

    }

    resize = () => {
        paper.view.viewSize = new Size(
            document.getElementById("view").offsetWidth, 
            document.getElementById("view").offsetHeight
        )
        paper.view.draw()
    }

    render() {
        return (
            <>  
                {
                    <InstuctionsModal
                        onClose={() => this.setState({instructionsModalActive: false})}
                        open={this.state.instructionsModalActive}
                    />
                }
                {
                this.state.selectExerciseActive ? 
                <Container sx={{height: "100%"}} maxWidth="md">
                     <Typography sx={{p: 3}} variant="h5" align="center" gutterBottom>
                            Select an exercise
                        </Typography>
                    <Box sx={{height: "calc(100% - 88px)", overflowY: "auto"}}>
                        <Grid sx={{px: 1, pb: 5}} container spacing={4}>
                        {
                            data.map((exercise, index) => (
                                <Grid 
                                    item 
                                    xs={12}
                                    sm={6}
                                    md={4}
                                >
                                    <Paper
                                        onClick={() => {
                                            this.setState({
                                                selectExerciseActive: false,
                                                selectedExercise: index
                                            }, () => this.setup())
                                        }}
                                        sx={{
                                            cursor: "pointer",
                                            position: "relative",
                                            height: "300px",
                                            borderRadius: "15px"
                                        }}
                                    >
                                        <img
                                            style={{
                                                borderRadius: "15px",
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                                position: "absolute"
                                            }}
                                            src={`${process.env.PUBLIC_URL}/exercises/${exercise.id}.png`} 
                                        />
                                        {/* {
                                            exercise.name
                                        } */}
                                    </Paper>
                                </Grid>
                        ))}
                        </Grid>
                    </Box>
                </Container>
                :
                <div style={{height: "100%", display: "flex"}}>
                    <div id="view" style={{width: "100%", height: "100%"}}>
                        <canvas 
                            id="canvas" 
                            resize="true"
                            data-paper-resize
                            onContextMenu={(e)=> e.preventDefault()}
                            ref={canvas => {this.canvas = canvas;}}
                            style={{
                                width: "100%", 
                                height: "100%",
                                backgroundColor: this.state.backgroundColor,
                                backgroundImage: `url(${this.state.backgroundSVG})`,
                            }} 
                            >
                        </canvas>
                        <Box
                            sx={{
                                position: "absolute",
                                bottom: "0px",
                                display: "flex",
                                justifyContent: "center",
                                alignContent: "center",
                                alignItems: "center",
                                flexDirection: "row",
                                width: "100%",
                                padding: "20px",
                                pointerEvents: "none"
                            }}
                        >
                            <Button
                                color="inherit"
                                sx={{mr: 2, pointerEvents: "auto"}}
                                variant="contained"
                                onClick={() => {
                                    // paper.traceIt = null
                                    // paper.remove()
                                    this.setState({selectExerciseActive: true})
                                } }
                            >
                                <ArrowBackIcon/>
                            </Button>
                            <Button
                                color="inherit"
                                sx={{mr: 2, pointerEvents: "auto"}}
                                variant="contained"
                                onClick={() => {
                                    // paper.traceIt = null
                                    // paper.remove()
                                    toggleFullscreen();
                                } }
                            >
                                <FullscreenIcon/>
                            </Button>
                            <Button
                                color="inherit"
                                sx={{mr: 2, pointerEvents: "auto"}}
                                onClick={() => focusOnLine(paper)}
                                variant="contained"
                            >
                                <CenterFocusWeakIcon/>
                            </Button>
                        </Box>
                    </div>
                </div>
                }
            </>
        )
    }

}
export default Tracing;

