import logo from './logo.svg';
import './App.css';
import TraceItExercise from './TraceItExercise';

function App() {
  return (
  
    <TraceItExercise/>
  );
}

export default App;
