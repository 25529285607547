import {Point} from "paper/dist/paper-core"
import {
    animatelineComplete,
    animateCircleIn,
    animateCameraZoom,
    animateLineIn,
    animateMoveToPoint
} 
from "./tracingAnimations"
import {HIT_ZONE} from "./tracingConstants"

/**
 * Setup mouse wheel scroll of canvas/paper
 * 
 * @param {Object} canvas 
 * @param {Paper} paper 
 */
export const setupMouseWheelListener = (canvas, paper) => {
    canvas.addEventListener('wheel', (e) => {
        const zoom = Math.sign(e.deltaY) * 0.5
        if (zoom > 0) 
            paper.view.zoom += 0.05
        else if (zoom < 0) 
            paper.view.zoom -= 0.05
    }, {passive: true})
}

/**
 * Setup pinch zoom
 * 
 * @param {Object} canvas 
 * @param {Paper} paper 
 */
export const setupPinchZoomListener = (canvas, paper) => {

    canvas.addEventListener("touchend", (e) => {
        paper.traceIt.previousPinchDistance = null
    }, {passive: true})

    canvas.addEventListener("touchmove", (e) => {

        if (e.touches.length === 2) {

            if (paper.traceIt.previousPinchDistance) {

                var pinchDistance = Math.hypot(
                    e.touches[0].pageX - e.touches[1].pageX,
                    e.touches[0].pageY - e.touches[1].pageY) 
                
                paper.view.zoom += ((pinchDistance - paper.traceIt.previousPinchDistance) * -0.005)
   
            }    
        
            // Store previus pinch distance
            paper.traceIt.previousPinchDistance = Math.hypot(
                e.touches[0].pageX - e.touches[1].pageX,
                e.touches[0].pageY - e.touches[1].pageY)
        }
    }, {passive: true})
}

/**
 * Setup tracing tool listeners
 * 
 * @param {Paper} paper 
 */
export const setupTracingTool = (paper, tracingTool, onLineCompleteCallback, onFinishLineCallback ) => {

    tracingTool.onMouseDown = (e) => {
        // Hit test
        var hit = paper.project.hitTest(e.point)

        if (hit && hit.item.type === HIT_ZONE) {
            paper.traceIt.panningActive = false
            paper.traceIt.currentHit = hit.item
        }
        else if (!hit) 
            paper.traceIt.panningActive = true
    
    }

    tracingTool.onMouseUp = () => {
        paper.traceIt.panningActive = false
        paper.traceIt.currentHit = null
    }

    tracingTool.onMouseDrag = (e) => {

        if (paper.traceIt.movingView) return

        if (paper.traceIt.panningActive) {
            paper.view.scrollBy(new Point((e.delta.x * 0.5) * -1, (e.delta.y * 0.5) * -1))            
        }

        if (!paper.traceIt.currentHit) return

        var hit = paper.traceIt.currentHit.topPath.hitTest(e.point)

        if (hit && hit.item === paper.traceIt.currentHit.topPath) {

            paper.traceIt.nearestLocation = paper.traceIt.currentHit.topPath.getNearestLocation(e.point)
            
            var distance = 0

            for (var i = 0; i < paper.traceIt.nearestLocation.index; i++) {
                distance += paper.traceIt.currentHit.topPath.segments[i].curve.length
            }

            var offset = paper.traceIt.currentHit.topPath.length - (distance + paper.traceIt.nearestLocation.curveOffset)

            /* Make sure line only goes forward */
            if (offset <= paper.traceIt.currentHit.topPath.dashOffset){
                paper.traceIt.currentHit.position = paper.traceIt.currentHit.topPath.getNearestPoint(e.point)
                paper.traceIt.currentHit.topPath.dashOffset = offset
            }

            const autoMoveBounds = 40 // pixels
            const boundsPoint = paper.view.projectToView(e.point)

            // Check if line finished
            if (distance + paper.traceIt.nearestLocation.curveOffset === paper.traceIt.currentHit.topPath.length) {
                animatelineComplete(
                    paper.traceIt.currentHit.topPath,
                    () => nextLine(paper, onLineCompleteCallback, onFinishLineCallback)
                )
                paper.traceIt.currentHit.visible = false // remove()
                paper.traceIt.currentHit = null
            }   
            // Check if line is close to the edge
            else if (boundsPoint.x <= autoMoveBounds || 
                boundsPoint.x >= (paper.view.viewSize.width - autoMoveBounds) ||
                boundsPoint.y <= autoMoveBounds || 
                boundsPoint.y >= (paper.view.viewSize.height - autoMoveBounds)) {

                if (!paper.traceIt.movingView) {
                    paper.traceIt.movingView = true
                    animateMoveToPoint(
                        paper,
                        paper.view.center,
                        new Point(
                            paper.traceIt.nearestLocation.point.x - paper.view.center.x,
                            paper.traceIt.nearestLocation.point.y - paper.view.center.y,
                        ),
                        750,
                        () => paper.traceIt.movingView = false
                    )
                }
            }

        }
    }      
}

export const focusOnLine = (paper) => {

    var goToPoint

    if (paper.traceIt.nearestLocation) 
        goToPoint = paper.traceIt.nearestLocation.point
    else if (paper.traceIt.bottomPaths[paper.traceIt.tracedLineIndex]) 
        goToPoint = paper.traceIt.bottomPaths[paper.traceIt.tracedLineIndex].segments[0].point

    if (goToPoint)
    {
        animateMoveToPoint(
            paper,
            paper.view.center,
            new Point(
                goToPoint.x - paper.view.center.x,
                goToPoint.y - paper.view.center.y,
            ), 
            750
        )
        
        animateCameraZoom(
            paper,  
            paper.view.zoom,  
            (paper.view.zoom * -1) + (paper.traceIt.zoomedLineWidth / paper.traceIt.topPaths[paper.traceIt.tracedLineIndex].style.strokeWidth),
            750
        )
    }
}

export const nextLine = (paper, onLineCompleteCallback, onFinishLineCallback) => {

    onLineCompleteCallback()

    // Increment current line index
    paper.traceIt.tracedLineIndex++
    paper.traceIt.nearestLocation = null

    // Check if all lines complete
    if ( paper.traceIt.tracedLineIndex !== paper.traceIt.topPaths.length) {
        
        paper.traceIt.topPaths[paper.traceIt.tracedLineIndex].visible = true
        paper.traceIt.bottomPaths[paper.traceIt.tracedLineIndex].visible = true
        paper.traceIt.hitGroup.children[paper.traceIt.tracedLineIndex].visible = true    

        animateCircleIn(
            paper.traceIt.hitGroup.children[paper.traceIt.tracedLineIndex], 
            0.1, 
            paper.traceIt.topPaths[paper.traceIt.tracedLineIndex].style.strokeWidth - 0.1, 
            750
        )
        
        animateLineIn(
            paper.traceIt.bottomPaths[paper.traceIt.tracedLineIndex], 
            0, 
            paper.traceIt.bottomPaths[paper.traceIt.tracedLineIndex].strokeWidth, 
            750
        )

        animateMoveToPoint(
            paper,
            paper.view.center,
            new Point(
                paper.traceIt.bottomPaths[paper.traceIt.tracedLineIndex].segments[0].point.x - paper.view.center.x,
                paper.traceIt.bottomPaths[paper.traceIt.tracedLineIndex].segments[0].point.y - paper.view.center.y,
            ), 
            750
        )
        
        animateCameraZoom(
            paper,  
            paper.view.zoom,  
            (paper.view.zoom * -1) + (paper.traceIt.zoomedLineWidth / paper.traceIt.topPaths[paper.traceIt.tracedLineIndex].style.strokeWidth),
            750
        )
    
    }
    else {

        onFinishLineCallback()

        const scaleRatio = Math.min(
            paper.view.bounds.width /  (paper.traceIt.pathsGroup.bounds.width + 40),
            paper.view.bounds.height /  (paper.traceIt.pathsGroup.bounds.height  + 40)
        )
      
        // // animate to center of viewport
          animateMoveToPoint(
            paper,
            paper.view.center,
            new Point(
                paper.traceIt.pathsGroup.bounds.center.x - paper.view.center.x,
                paper.traceIt.pathsGroup.bounds.center.y - paper.view.center.y,
            ),
            750
        )

        animateCameraZoom(
            paper,
            paper.view.zoom,  
            (scaleRatio * paper.view.zoom) - paper.view.zoom,
            750        
        ) 
    }
}

